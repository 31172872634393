<template>
  <div>

<!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50 select-size-sm"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="9"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Procurar..."
                debounce="600"
              />

            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refImportacaoListTable"
        class="position-relative"
        responsive
        striped
        hover
        :items="fetchImportacoes"
        :fields="tableColumns"  
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
      >
        <!-- Column: User -->
        <template #cell(nome_arquivo)="data">
          <b-link
            :href="data.item.link_arquivo"
            class="font-weight-bold d-block text-nowrap" 
          >
            {{ data.item.nome_arquivo }}
          </b-link>
        </template>

        <!-- Column: Status -->
        <template #cell(situacao)="data">
          <b-badge
            pill
            :variant="`light-${resolveImportacaoStatusVariant(data.item.situacao)}`"
            class="text-capitalize"
          >
            {{ data.item.situacao }}
          </b-badge>
        </template>


      </b-table>

      <!-- Paginação -->
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo de {{ dataMeta.from }} até {{ dataMeta.to }} de {{ dataMeta.total }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="dataMeta.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BModal,BFormFile, BForm, BFormGroup, BCardText
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useImportacaoList from './useImportacaoList'
import importacaoStoreModule from '../importacaoStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const IMPORTACAO_APP_STORE_MODULE_NAME = 'app-importacao'

    // Register module
    if (!store.hasModule(IMPORTACAO_APP_STORE_MODULE_NAME)) store.registerModule(IMPORTACAO_APP_STORE_MODULE_NAME, importacaoStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(IMPORTACAO_APP_STORE_MODULE_NAME)) store.unregisterModule(IMPORTACAO_APP_STORE_MODULE_NAME)
    })

    const situacaoOptions = [
      { label: 'Ativo', value: 'ATIVO' },
      { label: 'Inativo', value: 'INATIVO' }
    ]

    const {
      fetchImportacoes,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      refImportacaoListTable,
      refetchData,

      // UI
      resolveImportacaoStatusVariant,

      // Extra Filters
      situacaoFilter
    } = useImportacaoList()

    return {

      fetchImportacoes,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      refImportacaoListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveImportacaoStatusVariant,

      situacaoOptions,

      // Extra Filters
      situacaoFilter
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
